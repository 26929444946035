.item {
  list-style-type: none;
  button {
    &:disabled {
      cursor: not-allowed;
    }
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    color: var(--white-color);
    padding: 12px;
    width: 100%;
    text-align: start;
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    &:hover {
      background-color: var(--other-600);
    }
  }
}
.activeItem {
  &.highlightItem {
    button {
      background-color: var(--complementary-color);
      color: var(--primary-blue-850);
    }
  }
  button {
    background-color: var(--other-600);
    color: var(--primary-blue-850);
    svg {
      path {
        fill: var(--primary-blue-850);
      }
    }
  }
}

.highlightItem {
  button {
    color: var(--complementary-color);
  }
}
