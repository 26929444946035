.widget {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #E3F2FD;
  padding: 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rightSection {
  display: flex;
  gap: 4px;
  text-align: right;
  align-items: flex-end;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  

} 