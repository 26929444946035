.completed {
  color: var(--green-color);
  background-color: var(--light-green-color);
}
.in_delivery {
  color: var(--blue-text-color);
  background-color: var(--light-blue-color);
}
.canceled {
  color: var(--red-secondary-color);
  background-color: var(--light-red-color);
}

.none {
  color: var(--other-500);
  background-color: var(--other-50);
}

.delivered {
  color: var(--purple-500);
  background-color: var(--purple-50);
}

.loading {
  color: var(--orange-500);
  background-color: var(--orange-50);
}

.status {
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}
