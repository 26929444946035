.acm_filled,
.tp_filled,
.olme_filled,
.acm_confirmed {
  color: var(--green-color);
  background-color: var(--light-green-color);
}
.acm_waiting_quotation,
.tp_request,
.olme_request {
  color: var(--blue-text-color);
  background-color: var(--light-blue-color);
}
.acm_timeout,
.acm_admin_timeout,
.acm_admin_timeout_admin_signed,
.acm_user_timeout,
.acm_admin_rejected,
.acm_user_timeout_admin_signed,
.acm_user_rejected_quotation,
.acm_user_rejected_quotation_admin_signed,
.tp_request_admin_rejected_admin_signed,
.tp_request_user_rejected,
.tp_order_user_rejected,
.tp_request_user_rejected_admin_signed,
.tp_order_user_rejected_admin_signed,
.tp_order_admin_rejected_admin_signed,
.olme_order_admin_rejected,
.olme_order_admin_rejected_admin_signed,
.olme_request_admin_rejected_admin_signed,
.olme_request_user_rejected,
.olme_order_user_rejected,
.olme_order_user_rejected_admin_signed,
.olme_request_user_rejected_admin_signed {
  color: var(--red-secondary-color);
  background-color: var(--light-red-color);
}

.none {
  color: var(--other-500);
  background-color: var(--other-50);
}

.delivered {
  color: var(--purple-500);
  background-color: var(--purple-50);
}

.acm_waiting_quotation_reaction,
.tp_order,
.olme_order {
  color: var(--orange-500);
  background-color: var(--orange-50);
}

.status {
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.statusMobile {
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
}
