.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.shipmentsList {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  position: sticky;
  bottom: 0;
  padding: 16px;
  background-color: var(--white-color);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
} 