.shipmentItem {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--other-300);
  background: var(--white-color);

  &.selectable {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--grey-50);
    }
  }
}

.shipmentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.shipmentDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
} 