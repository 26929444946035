.menuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menuContent {
  flex: 1;
  padding: 16px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
} 
.logo {
  font-size: 1.25rem;
  font-weight: 500;
  span {
    color: #C7C6C6
  }
}