.container {
  max-width: 958px;
  margin: 0 auto;
  padding: 0 8px 40px 8px;
}

h5,
h3,
h2,
p {
  margin: 20px;
}

h5 {
  text-decoration: underline;
}
