.dialog {
  margin: 16px;
  height: 600px;
  text-align: left;
  span {
    color: #000;
  }
}

.container {
  padding: 28px 16px 16px 16px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  gap: 10px;

}

.iconWrapper {
  width: 120px;
  height: 120px;
  background-color: #F5FBFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}



.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;
}

.message {
  color: var(--other-900);
  margin: 12px 0;
  font-size: 16px;
  line-height: 20.8px;
}

.actions {
  width: 100%;
} 