.headerContainer {
  position: relative;
  z-index: 1100;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--other-900);
  color: white;
}

.logo {
  font-size: 1.25rem;
  font-weight: 500;
  span {
    color: #C7C6C6
    }
}

