.header {
  background-color: var(--other-900);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 0;
}

.controls {
  display: flex;
  gap: 18px;
  align-items: center;
  color: var(--white-color);
}

.userMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .dropdownIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 48px;
    height: 48px;
  }

  .openDropdownIcon {
    svg {
      transition: 0.4s ease;
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }

  .userMenuHeader {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 22px;
    padding: 6px;
    border-radius: 8px;
    transition: all 0.4s ease;

    &:hover {
      background: #f1f7fc;
    }

    .accountInfo {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .email {
        height: 16px;
        color: #0d2048;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .version {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #5d6a84;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        gap: 4px;
      }
    }
  }

  .userMenuHeaderActive {
    background: #f1f7fc;
  }

  .userMenuDropdown {
    width: 160px;
    z-index: 3;
    position: absolute;
    top: calc(100% - 8px);
    right: 0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 4px 16px 0px #00000014;
    overflow: hidden;

    button {
      width: 100%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ul {
      width: 160px;
      padding-inline-start: 0;
      margin: 0;

      li {
        list-style: none;
        background-color: #fff;

        &.logout {
          border-top: 1px solid var(--neutral-gray-100);

          button {
            color: var(--red-600);
          }
        }

        &.settings {
          button {
            border: 0px;
            color: var(--neutral-gray-700);
          }
        }
      }
    }
  }
}
