.acm_filled,
.tp_filled,
.olme_filled,
.acm_confirmed {
  color: var(--green-color);
}

.acm_waiting_quotation,
.tp_request,
.olme_request {
  color: var(--blue-text-color);
}

.acm_timeout,
.acm_user_timeout,
.acm_admin_rejected,
.acm_user_rejected_quotation,
.tp_request_user_rejected,
.tp_order_user_rejected,
.olme_order_admin_rejected,
.olme_request_user_rejected,
.olme_order_user_rejected {
  color: var(--red-secondary-color);
}

.none {
  color: var(--other-500);
}

.delivered {
  color: var(--purple-500);
}

.acm_waiting_quotation_reaction,
.tp_order,
.olme_order {
  color: var(--orange-500);
}
