.invited {
  color: var(--orange-color);
  background-color: var(--light-orange-color);
}

.active {
  color: var(--green-color);
  background-color: var(--light-green-color);
}

.frozen {
  color: var(--red-secondary-color);
  background-color: var(--light-red-color);
}

.none {
  color: var(--other-500);
  background-color: var(--other-50);
}

.status {
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}
