.dialog {
    margin: 16px;
    height: 350px;
    text-align: left;
    span {
      color: #000;
    }
  }
  
  .message {
    color: var(--other-900);
    margin: 12px 0;
    font-size: 16px;
    line-height: 20.8px;
  }
  
