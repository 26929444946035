.searchContainer {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--other-900);
  color: #fff;
}

.input {
  flex: 1;
  background-color: #1F3660;
  border-radius: 8px;
}
