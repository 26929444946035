.sidebar {
  background-color: var(--other-900);
  color: var(--white-color);
  padding: 20px;
  border-right: 1px solid var(--neutral-gray-400);
  height: 100vh;
  position: sticky;
  top: 0;
  box-sizing: border-box;
}

