@import url('https://fonts.googleapis.com/css2?family=Onest:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Onest', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Onest', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --white-color: #fff;
  --red-color: #d32f2f;
  --orange-color: #ffa726;
  --red-secondary-color: #f44336;
  --green-color: #66bb6a;
  --blue-color: #29b6f6;
  --pink-color: #ef5da8;
  --violet-color: #5d5fef;

  --main-text-color: #0c2452;
  --fill-text-color: #8592a8;
  --blue-text-color: #2196f3;

  --light-white-color: #f7f7f7;
  --light-gray-primary-color: #ebebeb;
  --light-gray-secondary-color: #cbcbcb;
  --light-blue-color: #e3f2fd;
  --light-pink-color: #fcdfee;
  --light-green-color: #e0f1e1;
  --light-orange-color: #ffedd4;
  --light-red-color: #ffebee;
  --light-violet-color: #dfdffc;

  --orange-500: #ff9800;
  --orange-50: #fff3e0;

  --other-900: #0c2452;
  --other-600: #3a517e;
  --other-500: #9e9e9e;
  --other-300: #e0e0e0;
  --other-100: #f5f5f5;
  --other-50: #fafafa;

  --purple-500: #673ab7;
  --purple-50: #ede7f6;

  --primary-color: #2265e3;
  --primaty-color-100: #1976d2;
  --primary-color-400: #1565c0;
  --primary-color-800: #0c58af;

  --complementary-color: #e39f22;

  color: var(--other-900);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
q {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 32px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 500;
}

p {
  font-size: 14px;
  font-weight: 400;
}

q {
  font-size: 12px;
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
