.dialog {
  margin: 16px;
}

.container {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.title {
  color: var(--navy-900);
  font-size: 28px;
  font-weight: 600;
}

.options {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 0;
  border-bottom: 1px solid var(--other-100);


}

.actions {
  margin-top: 8px;
} 