.commonHeader {
  background: #f9fafb;
}

.sellerHeader {
  background: #ede7f6;
}

.buyerHeader {
  background: #e3f2fd;
}

.border {
  border-right: 1px solid #f5f5f5;
}
