:root {
  --title: 60px;
  --title2: 44px;
  --title3: 24px;
  --subtitle: 20px;
  --body: 16px;
  --text-blue: #0c2452;
  --text-black: #000;
  --main: #9ea7ba;
  --card-hover: #f9f9f9;
  --gray: #cdcccf;
}

.landing {
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  p {
    font-family: 'Codec-Pro Regular', sans-serif;
    margin: 0;
    padding: 0;
    cursor: default;
  }

  h1 {
    font-size: var(--title);
    line-height: 70px;
    color: #000;
  }
  h2 {
    font-size: var(--title2);
    color: var(--text-black);
    line-height: 1.35;
    font-weight: 500;
  }

  h3 {
    font-size: var(--title3);
    font-weight: 600;
    line-height: 1.55;
    color: var(--text-black);
  }

  h4 {
    font-size: var(--subtitle);
    color: var(--main);
    font-weight: 400;
    margin-top: 12px;
  }

  h5 {
    font-size: var(--body);
    font-weight: 500;
    color: var(--text-blue);
  }

  p {
    font-size: var(--body);
    margin-top: 12px;
    line-height: 27px;
    font-weight: 300;
    color: var(--text-black);
  }

  a {
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    color: var(--text-black);
    cursor: pointer;
  }

  span {
    color: #021cf5;
  }

  button {
    background-color: #0000ff;
    color: white;
    padding: 10px 30px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0000cc;
    }

    &:focus {
      outline: none;
    }

    &:active {
      background-color: #000099;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 90px;
    h2 {
      font-size: 66px;
      font-weight: 700;
    }
  }
}

.content {
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
}

.header {
  background: #989898;
  backdrop-filter: blur(54px);
  -webkit-backdrop-filter: blur(54px);
  height: 62px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 11;

  .header__logo h2 {
    color: rgb(51, 51, 51);
    font-weight: 700;
    cursor: pointer;
  }

  .header__content {
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 30px;
  }

  .navigation__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
}

.navigation ul {
  list-style-type: none;
  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navigation li a {
  color: #000;
  font-weight: 600;
  line-height: 52px;
}

.hero {
  width: 100%;
  padding: 50px 0;
  margin: 0 auto;
  background: #989898;

  h1 {
    font-size: 5rem;
    margin-bottom: 10px;
  }

  h3 {
    color: #fff;
    margin-bottom: 70px;
  }

  .hero__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    margin-top: 30px;
    padding: 15px 40px;
    font-size: 18px;
  }

  .hero__text {
    max-width: 458px;
  }
}

.whyChoose {
  padding: 40px;
  background-color: #fff;
  text-align: center;
  margin-bottom: 120px;

  .title {
    font-size: 66px;
    font-weight: bold;
    margin: 70px 0;
  }

  .highlight {
    color: #021cf5;
  }

  .columns {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .column {
    background-color: #efefef;
    padding: 30px;
    border-radius: 5px;
    max-width: 360px;
    margin-top: 30px;
    height: 850px;
    box-shadow: 0px 15px 30px -10px rgba(0, 11, 48, 0.2);
    text-align: left;

    h3 {
      font-size: 20px;
      margin-bottom: 35px;

      .highlight {
        color: #021cf5;
        font-weight: bold;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;

        strong {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    }
  }

  .tallerColumn {
    padding-top: 55px;
    min-height: 900px;
  }

  .column:first-child,
  .column:last-child {
    align-self: center;
  }
}

.howItWorks {
  text-align: center;
  margin-bottom: 160px;
  padding-top: 160px;
  .title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 120px;
  }

  .steps {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .stepCard {
    background-color: #fff;
    padding: 40px;
    width: 360px;
    border: 1px solid rgb(224, 224, 224);

    position: relative;
    text-align: left;

    .stepNumber {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      border: 1px solid #000;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
    }

    .stepTitle {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .stepContent {
      font-size: 14px;

      .textWrapper {
        display: flex;
        flex-direction: column;
        height: 150px;
        gap: 20px;
      }
      ul {
        margin-top: 10px;
        padding-left: 20px;

        li {
          margin-bottom: 5px;
          position: relative;
        }
      }
      p {
        font-size: 15px;
        font-weight: 300;
        line-height: 23px;

        &.centerText {
          text-align: center;
        }
      }
      strong {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .icon {
      margin-top: 70px;
      text-align: center;

      img {
        width: 100px;
      }
    }
  }
}

.coreBenefits {
  padding: 140px 0;
  background-color: #121212;
  color: fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #fff;
    font-size: 66px;
    font-weight: 600;
    margin-bottom: 70px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .benefitCard {
    background-color: #fff;
    text-align: left;
    color: #000;
    height: 370px;
    max-width: 300px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    }

    .icon {
      margin-bottom: 60px;

      img {
        width: 80px;
      }
    }

    .benefitTitle {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .benefitDescription {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 100%;
  padding: 100px;
  background: #efefef;

  h3 {
    margin-bottom: 24px;
  }
  p {
    line-height: 1.55;
  }
  .textWrapper {
    max-width: 500px;
  }
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 100px;
  }
}

.experience {
  padding-top: 80px;
  margin-bottom: 200px;
  .title {
    margin-top: 120px;
    margin-bottom: 100px;
    text-align: center;

    h2 {
      font-size: 66px;
    }
  }

  .slider {
    position: relative;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .control {
      position: absolute;
      z-index: 10;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        display: flex;
        cursor: pointer;
        border: none;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: #000;
        outline: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0px 8px 16px rgba(19, 46, 63, 0.12);
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #7dc8f7;
        }
      }
    }
    .container {
      max-width: 1000px;
      margin: 0 auto;
      overflow: hidden;
      .wrapper {
        width: 9999px;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item {
          margin: 0;
          margin-right: 35px;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;
          width: 310px;
          height: 458px;
          padding: 20px;

          &:hover {
            transform: scale(1.05);
          }

          .textWrapper {
            display: flex;
            flex-direction: column;

            h3 {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

.counterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  overflow: hidden;
}

.counter {
  font-size: 70px;
  color: #fff;
  animation: scrollUp 1s ease-out forwards;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.numbers {
  background: #000;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;

  .title {
    h2 {
      color: #fff;
    }
  }

  p {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.faq {
  padding: 140px;

  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .list {
    max-width: 800px;
    margin: 0 auto;
  }

  .item {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      h3 {
        font-size: 20px;
        margin: 0;
      }

      .icon {
        font-size: 24px;
        font-weight: bold;
        transition: transform 0.3s ease;
      }

      .open {
        transform: rotate(45deg);
      }
    }

    .answerWrapper {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }

    .answerWrapper.open {
      max-height: 500px;
    }

    .answer {
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.5;
      color: #555;
    }
  }
}

.contact {
  padding: 50px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  padding-top: 160px;

  .wrapper {
    display: flex;
    justify-content: space-between;
    // align-items: flex-start;
    align-items: center;
    gap: 100px;
    margin-bottom: 100px;

    .info {
      h2 {
        font-size: 48px;
        margin-bottom: 20px;
      }

      .phone,
      .email {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .address {
        font-size: 16px;
        line-height: 1.6;
        color: #000;
        margin-top: 20px;
        font-style: normal;
      }
    }

    .form {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      input,
      textarea {
        width: 100%;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        font-size: 16px;
        background-color: transparent;
      }

      textarea {
        resize: none;
        height: 150px;
      }

      button {
        padding: 15px 30px;
        border: none;
        border-radius: 50px;
        background-color: blue;
        color: white;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          background-color: darkblue;
        }
      }
    }
  }
}

.footer {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: 25px;

  p,
  .navigation li a {
    color: #fff;
  }
  .backToTop {
    background: transparent;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

@media (max-width: 684px) {
  :root {
    --title: 32px;
    --title2: 24px;
    --title3: 16px;
    --subtitle: 16px;
    --body: 12px;
  }

  h1 {
    line-height: 37.5px;
  }

  .hero__content {
    padding-top: 80px;
  }

  .coreFunctions {
    padding: 60px 0;
  }

  .contact__content,
  .experience__content {
    padding-top: 60px;
  }

  .about__content {
    height: 211px;
  }

  .navigation {
    display: none !important;
  }

  .slider__control {
    display: none;
  }

  .slider__item {
    height: 385px;
  }

  .slider__item figcaption {
    padding: 42px 12px 0 12px;
  }

  .card {
    width: 158px;
    padding: 20px 10px 0 10px;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
