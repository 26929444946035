.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  padding: 16px;
  background: var(--white-color);
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--other-100);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.headerInfo {
  display: flex;
  gap: 8px;
  align-items: center;
}


.details {
  background-color: var(--white-color);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipments {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: var(--other-50);
}


.actions {
  position: sticky;
  bottom: 0;
  padding: 16px;
  background-color: var(--white-color);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
} 