.card {
  background-color: var(--white-color);
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--other-100);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.status {
  text-align: right;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
} 