.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}

.children {
  grid-area: children;
  background-color: var(--other-50);
}

.accountLayout {
  min-height: 100%;
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar  children';
  grid-template-columns: 300px 1fr;
  grid-template-rows: 80px 1fr;
}
