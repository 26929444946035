.active {
  color: var(--green-color);
  background-color: var(--light-green-color);
}
.filled {
  color: var(--blue-text-color);
  background-color: var(--light-blue-color);
}
.canceled {
  color: var(--red-secondary-color);
  background-color: var(--light-red-color);
}

.status {
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}
