.card {
  padding: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 16px;
  border: 1px solid var(--other-300);
  background: #fff;

  &:hover {
    transform: translateY(-2px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 16px;
}

.stat {
  background-color: #FAFAFA;
  padding: 4px 8px;
  border-radius: 8px;
  text-align: left;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
} 