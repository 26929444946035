.main {
	padding-top: 9vh;
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	height: 58px;
	justify-content: space-around;
}

// Main info

.mainInfo_title {
	display: flex;
	flex-direction: column;
	padding-top: 67px;
	padding-bottom: 20px;
}

.emptyAvatar {
	background-color: var(--blue-text-color);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	font-size: 24px;
	color: var(--main-text-color);
	margin: 0;
	text-align: center;
	opacity: 0.4;
	line-height: 31px;
	p {
		font-size: 24px;
		color: var(--main-text-color);
		margin: 0;
		text-align: center;
		opacity: 0.4;
		line-height: 31px;
	}
}


.tableContent {
	p {
	  margin: 0;
	  font-size: 16px;
	  color: var(--fill-text-color);
	  strong {
		color: var(--main-text-color);
		font-size: 20px;
		font-weight: 600;
	  }
	}
	h5 {
	  margin: 0;
	  margin-top: 33px;
	  font-size: 16px;
	  font-weight: 700;
	  color: var(--fill-text-color);
	}
  }