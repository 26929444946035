.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-50);
}

.content {
  flex: 1;
  padding-bottom: env(safe-area-inset-bottom);
} 